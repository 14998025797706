import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LimitReportListBaseComponent } from '@backoffice-frontend/limit-report/domain';
import { LimitReportPowerListFacade } from '../../services/limit-report-list.facade';

@Component({
  selector: 'backoffice-frontend-limit-report-power-list',
  templateUrl: './limit-report-list.component.html',
  styleUrls: ['./limit-report-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LimitReportPowerListComponent extends LimitReportListBaseComponent {
  protected baseNavigateUrl = '/limit-reports/power';

  constructor(service: LimitReportPowerListFacade) {
    super(service);
  }
}
