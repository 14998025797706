import { Route } from '@angular/router';
import { CanDeactivateGuard } from '@clean-code/shared/components/ui-can-deactivate';
import { faPlug } from '@fortawesome/pro-light-svg-icons';

export const limitReportPowerDetails: Route = {
  path: 'limit-report/:id',
  children: [
    {
      path: '',
      loadComponent: () =>
        import('./limit-report-power-tab.component').then(
          (mod) => mod.LimitReportPowerTabComponent
        ),
      data: {
        translate: 'limit-reports',
        titleIcon: {
          icon: faPlug,
          class: 'power-icon',
        },
      },
      children: [
        { path: '', redirectTo: 'details', pathMatch: 'full' },
        {
          path: 'details',
          loadComponent: () =>
            import('../limit-report-form/limit-report-form.component').then(
              (mod) => mod.LimitReportPowerFormComponent
            ),
          canDeactivate: [CanDeactivateGuard],
          data: {
            label: 'limit-reports.tab.DETAILS',
          },
        },
        {
          path: 'settings',
          loadComponent: () =>
            import(
              '@backoffice-frontend/limit-report/configuration/ui-configuration'
            ).then((mod) => mod.LimitReportSettingsFormComponent),
          canDeactivate: [CanDeactivateGuard],
          data: {
            label: 'limit-reports.tab.COMMON_REPORT_SETTINGS',
            disable: true,
          },
        },
        {
          path: 'passive',
          loadComponent: () =>
            import(
              '@backoffice-frontend/limit-report/configuration/ui-configuration'
            ).then((mod) => mod.LimitReportSettingPassiveComponent),
          canDeactivate: [CanDeactivateGuard],
          data: {
            label: 'customer-pools.tab.SETTINGS_PASSIVE',
            disable: true,
          },
        },
        {
          path: 'dynamic_limit_a1',
          loadComponent: () =>
            import(
              '@backoffice-frontend/limit-report/configuration/ui-configuration'
            ).then((mod) => mod.LimitReportDynamicLimitFormComponent),
          canDeactivate: [CanDeactivateGuard],
          data: {
            label: 'limit-reports.tab.DYNAMIC_REPORT_SETTINGS_A1',

            disable: true,
          },
        },
        {
          path: 'dynamic_limit_a2',
          loadComponent: () =>
            import(
              '@backoffice-frontend/limit-report/configuration/ui-configuration'
            ).then((mod) => mod.LimitReportConfigurationComponent),
          canDeactivate: [CanDeactivateGuard],
          data: {
            label: 'limit-reports.tab.TECHNICAL_INDICATORS_A2',
            disable: true,
          },
        },
        {
          path: 'other_report_settings',
          loadComponent: () =>
            import(
              '@backoffice-frontend/limit-report/configuration/ui-configuration'
            ).then((mod) => mod.LimitReportOtherSettingsFormComponent),

          canDeactivate: [CanDeactivateGuard],
          data: {
            label: 'limit-reports.tab.OTHER_REPORT_SETTINGS',
            disable: true,
          },
        },
        {
          path: 'positions',
          children: [
            {
              path: '',
              loadComponent: () =>
                import(
                  '../limit-report-position-list/limit-report-position-list.component'
                ).then((mod) => mod.LimitReportPositionPowerListComponent),
              children: [
                {
                  path: 'preview/:positionId',
                  loadComponent: () =>
                    import(
                      '../limit-report-position-form/limit-report-position-form.component'
                    ).then((mod) => mod.LimitReportPositionPowerFormComponent),
                },
              ],
            },
          ],
          data: {
            label: 'limit-reports.tab.POSITIONS',
            disable: true,
          },
        },
        {
          path: 'overview',
          loadComponent: () =>
            import('../limit-report-review/limit-report-review.component').then(
              (mod) => mod.LimitReportPowerReviewComponent
            ),
          data: {
            label: 'limit-reports.tab.LIMIT_REPORTS_OVERVIEW',
            disable: true,
          },
        },
      ],
    },
  ],
};
