<cc-dashboard-page-container [showTitle]="showTitle">
  <div title>
    <span>{{ 'limit-reports.TITLE_REPORTS_POWER_PLURAL' | transloco }}</span>
  </div>

  <cc-table-preview page-content>
    <cc-table-container [isLoading]="isLoading$ | async">
      <ng-container table-filter>
        <limit-report-power-search></limit-report-power-search>
      </ng-container>
      <div table-buttons class="flex flex-col sm:flex-row">
        <cc-add-action-button
          (onClick)="add()"
          *ngxPermissionsOnly="[
            permissions.limitReportPowerEditor,
            permissions.limitReportPowerAdmin
          ]"
        >
        </cc-add-action-button>

        <cc-delete-action-button
          (onClick)="deleteRow()"
          [disabled]="!selected"
          *ngxPermissionsOnly="[
            permissions.limitReportPowerEditor,
            permissions.limitReportPowerAdmin
          ]"
        >
        </cc-delete-action-button>
      </div>
      <bo-mat-table
        #table
        matSort
        [data]="paging$ | async"
        [hiddenTableColumns]="tableColumns"
        (matSortChange)="onSort($event)"
        (pageChange)="setPage($event)"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'limit-reports.fields.REPORT_NAME' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'limit-reports.fields.CUSTOMER' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.customer }}
          </td>
        </ng-container>

        <ng-container matColumnDef="timeSeriesIdentifierProductName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{
              'limit-reports.fields.TIMESERIES_IDENTIFIER_PRODUCT_NAME'
                | transloco
            }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.timeSeriesIdentifierProductName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'common.CREATED_BY' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'common.CREATED_DATE' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdDate | date : 'shortDate' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="updatedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'limit-reports.fields.UPDATED_DATE' | transloco }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.updatedDate | date : 'shortDate' }}
          </td>
        </ng-container>
      </bo-mat-table>
    </cc-table-container>
  </cc-table-preview>
</cc-dashboard-page-container>
